<template>
    <v-snackbar :value="snackbar.isActive" v-bind="snackBarProps" elevation="24">
       <p class="dark--text mb-0"> {{ snackbar.text }}</p>

        <template v-slot:action="{ attrs }">
            <v-btn color="indigo" text v-bind="attrs" @click="closeSnack">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "Snackbar",
    props: {},
    computed: {
        ...mapGetters({
            snackbar: "snackbar/snackbar",
        }),
        snackBarProps() {
            return {
                color: "white",
                top: true,
                timeout: 5000,
                ...this.snackbar.props,
            };
        },
    },
    data() {
        return {};
    },
    methods: {
        ...mapActions({ closeSnackbar: "snackbar/closeSnackbar" }),
        closeSnack() {
            this.closeSnackbar();
        },
    },
};
</script>

<style scoped></style>
